import React from "react";
import Title from "./Title";
import UserField from "./UserField";

export default function UserInfo(props) {
  const getList = () => {
    var get = require("lodash.get");

    return props.options.itemList.map((option, index) => {
      return (
        <UserField
          option={option}
          value={get(props.userData, option.name)}
          handleChange={props.handleChange}
          getRandomCard={props.getRandomCard}
          addLeagueBots={props.addLeagueBots}
        />
      );
    });
  };
  return (
    <React.Fragment>
      <Title>{props.options.sectionTitle}</Title>
      <br />
      {getList()}
    </React.Fragment>
  );
}
