import { Button, Container, Grid, Paper, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import axios from 'axios'

const useStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    minHeight: 240,
  },
  paperCol: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  updateButton: {
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
  },
  startPaper: {
    padding: theme.spacing(2),
    display: "flex",
    backgroundColor: "#546bd6",
    justifyContent: "center",
  },
  inputBar: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  autocomplete: {
    display: "inline-flex"
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      fontWeight: 800,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const LeaderboardView = ({ classes , dev ,enqueueSnackbar}) => {
  const [leaderboard, setLeadeboard] = React.useState("")
  const [cycleTime, setCycleTime] = React.useState(0)
  const [leaderboards, setLeaderboards] = React.useState([])
  
  useEffect(() => {
    const getLeaderboardsList = () => {
      axios
      .get('/api/dev/' + dev + '/consul/devTestLeaderboardsConfig')
      .then(json => json.data)
      .then(data => {
        setLeaderboards(data)
      })
      .catch(err => {
       enqueueSnackbar("Something went wrong. " + err, {
          variant: "error",
        });
      })
    }

    getLeaderboardsList()
  },[])

  const postLeaderboardUpdate = (updateType) => {
    var payload = {"leaderboard" : leaderboard , "update_type" : updateType , "cycle_time":cycleTime}
    axios
    .post('/api/dev/' + dev + '/consul/devTestLeaderboardsConfig', payload)
    .then(json => json.data)
    .then(data => {
      setLeadeboard("")
      setCycleTime(0)
      if (data.err) {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Leaderboard Updated Successfully!", {
          variant: "success",
        });
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const getCycleOptions = () => {
    var cycleOptions  = []
    for(var i=1;i<=60;i++){
      if (60%i == 0){
        cycleOptions.push(i.toString())
      }
    }
    return cycleOptions
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}></Grid>

      </Grid>
      <Grid container spacing={3} style={{ marginTop: 30 }}>
        <Paper className={classes.startPaper}>
          <Grid justify="space-between" container spacing={3}>
            <Grid item className={classes.inputBar}>
              <Autocomplete
                className={classes.autocomplete}
                id="leaderboard-name"
                onChange={(ev, value) => {setLeadeboard(value)}}
                value={leaderboard}
                options={leaderboards}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option.id === value.id}
                style={{ width: 400, margin: 20 }}
                renderInput={(params) => <CssTextField {...params} label="Leaderboard" variant="outlined" />}
              />
              <Autocomplete
                className={classes.autocomplete}
                id="leaderboard-cycle-time"
                onChange={(ev, value) => {setCycleTime(Number(value))}}
                value={cycleTime.toString()}
                options={getCycleOptions()}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option.id === value.id}
                style={{ width: 400, margin: 20 }}
                renderInput={(params) => <CssTextField {...params} label="Cycle time (Minutes)" variant="outlined" />}
              />
              <Button
                className={classes.updateButton}
                aria-haspopup="true"
                aria-controls="lock-menu"
                variant="contained"
                disabled={leaderboard === "" || cycleTime === 0}
                onClick={() => postLeaderboardUpdate(1)}
              >
                Update
              </Button>
              <Button
                className={classes.updateButton}
                aria-haspopup="true"
                aria-controls="lock-menu"
                variant="contained"
                disabled={leaderboard === "" || cycleTime != 0}
                onClick={() => postLeaderboardUpdate(0)}
              >
                Reset to default
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container >
  );

}

LeaderboardView.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(withSnackbar(LeaderboardView));