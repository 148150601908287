import React from "react";
import clsx from "clsx";
import { withStyles, Container, Grid, Paper, Button } from "@material-ui/core";
import { Component } from "react";
import { PropTypes } from "prop-types";
import TextField from "@material-ui/core/TextField";
import UserInfo from "../components/UserInfo";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import axios from "axios";
import { UserInfoList } from "../UserData";
import { withSnackbar } from "notistack";
import ReactJson from "react-json-view";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperCol: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    minHeight: 240,
  },
  textField: {
    width: 150,
  },
  submitButton: {
    width: 150,
  },
  iconButton: {
    marginLeft: 10,
  },
  startPaper: {
    padding: theme.spacing(2),
    display: "flex",
    backgroundColor: "#546bd6",
    justifyContent: "center",
  },
  blobPaper: {
    padding: theme.spacing(2),
  },
  playIcon: {
    color: "white",
  },
  updateButton: {
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
  },
  deleteButton: {
    backgroundColor: "#ec1010",
    color: "white",
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
  },
  commandText: {
    width: "100%",
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      fontWeight: 800,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

class User extends Component {
  getUser = () => {
    axios
      .get("api/dev/" + this.props.dev + "/user/" + this.props.gameCode)
      .then((json) => json.data)
      .then((data) => {
        if (data.err) {
          this.props.enqueueSnackbar("User Not Found", {
            variant: "error",
          });
          this.props.setUserData({});
          this.props.setUserLeagueData({});
          this.props.setLeagueData({});
          console.log(data.err);
        } else {
          this.props.enqueueSnackbar("User Acquired Succesfully", {
            variant: "success",
          });
          this.props.setUserData(data["userData"]);
          this.props.setUserLeagueData(data["userLeagueData"]);
          this.props.setLeagueData(data["leagueData"]);
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar("User Not Found", {
          variant: "error",
        });
        console.log(err);
        this.props.setUserData({});
        this.props.setUserLeagueData({});
        this.props.setLeagueData({});
      });
  };

  updateUser = () => {
    axios
      .post(
        "api/dev/" + this.props.dev + "/user/" + this.props.gameCode,
        this.props.userData
      )
      .then((json) => json.data)
      .then((data) => {
        console.log(data);
        if (data.err) {
          this.props.enqueueSnackbar("User Not Updated", {
            variant: "error",
          });
          this.props.setUserData({});
          console.log(data.err);
        } else {
          this.props.enqueueSnackbar("User Updated", {
            variant: "success",
          });
          this.props.setUserData(data);
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar("User Not Updated", {
          variant: "error",
        });
        this.props.setUserData({});
        console.log(err);
      });
  };

  runCommand = () => {
    console.log(this.props.command);
    axios
      .post(
        "api/command/dev/" + this.props.dev + "/user/" + this.props.gameCode,
        {
          command: this.props.command,
        }
      )
      .then((json) => json.data)
      .then((data) => {
        console.log(data);
        if (data.err) {
          this.props.enqueueSnackbar("Invalid Command", {
            variant: "error",
          });
          console.log(data.err);
        } else {
          this.props.enqueueSnackbar("Command Run Success", {
            variant: "success",
          });
          this.props.setUserData(data);
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar("Invalid Command", {
          variant: "error",
        });
        console.log(err);
      });
  };

  deleteUser = () => {
    axios
      .delete(
        "api/dev/" + this.props.dev + "/user/" + this.props.gameCode,
        this.props.userData
      )
      .then((json) => json.data)
      .then((data) => {
        console.log(data);
        if (data.err) {
          this.props.enqueueSnackbar("User Not Deleted", {
            variant: "error",
          });
          console.log(data.err);
        } else {
          this.props.enqueueSnackbar("User Deleted", {
            variant: "success",
          });
        }
        this.props.setUserData({});
      })
      .catch((err) => {
        this.props.enqueueSnackbar("User Not Deleted", {
          variant: "error",
        });
        this.props.setUserData({});
        console.log(err);
      });
  };

  getRandomCard = (cardDispensingType) => {
    let cardUrl =
      cardDispensingType == 0 ? "/getRandomCardOldAlbum/" : "/getRandomCard/";
    if (cardDispensingType == 2) {
      cardUrl = "/getCard/";
    }
    axios
      .post(
        "api/dev/" + this.props.dev + cardUrl + this.props.gameCode,
        this.props.userData
      )
      .then((json) => json.data)
      .then((data) => {
        console.log(data);
        if (data.err) {
          this.props.enqueueSnackbar("Unable to give card", {
            variant: "error",
          });
          console.log(data.err);
        } else {
          this.props.enqueueSnackbar("Random card given", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar("Unable to give card", {
          variant: "error",
        });
        console.log(err);
      });
  };

  addLeagueBots = () => {
    axios
      .post(
        "api/dev/" + this.props.dev + "/addLeagueBots/" + this.props.gameCode
      )
      .then((json) => json.data)
      .then((data) => {
        console.log(data);
        if (data.err) {
          this.props.enqueueSnackbar("Unable add League Bots", {
            variant: "error",
          });
          console.log(data.err);
        } else {
          this.props.enqueueSnackbar("League Bots Added", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar("Unable to add League Bots", {
          variant: "error",
        });
        console.log(err);
      });
  };

  render() {
    const { classes } = this.props;
    const fixedHeightPaper = clsx(classes.paperCol, classes.fixedHeight);
    return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.startPaper}>
              <Grid justify="space-between" container spacing={3}>
                <Grid item>
                  <CssTextField
                    className={classes.margin}
                    label="GameCode"
                    id="user-gameCode"
                    name="user-gameCode"
                    autoComplete="on"
                    onChange={this.props.handleGameCodeChange}
                    variant="outlined"
                    value={this.props.gameCode}
                  />
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    className={classes.iconButton}
                    onClick={this.getUser}
                  >
                    <PlayCircleFilled
                      fontSize="large"
                      className={classes.playIcon}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.updateButton}
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    variant="contained"
                    disabled={Object.keys(this.props.userData).length === 0}
                    onClick={this.updateUser}
                  >
                    Update
                  </Button>
                  <Button
                    className={classes.deleteButton}
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    variant="contained"
                    disabled={Object.keys(this.props.userData).length === 0}
                    onClick={this.deleteUser}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.startPaper}>
              <Grid justify="space-between" container spacing={3}>
                <Grid item xs={10}>
                  <CssTextField
                    className={classes.commandText}
                    label="Command"
                    id="user-command"
                    name="user-command"
                    onChange={this.props.handleUserCommandChange}
                    variant="outlined"
                    value={this.props.command}
                  />
                </Grid>
                <Grid
                  xs={2}
                  item
                  style={{ display: "flex", flexFlow: "column-reverse" }}
                >
                  <Button
                    className={classes.updateButton}
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    variant="contained"
                    disabled={this.props.gameCode.length < 8}
                    onClick={this.runCommand}
                  >
                    RUN
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.blobPaper}>
              <ReactJson src={this.props.userData} collapsed />
              <ReactJson
                name="userLeagueData"
                src={this.props.userLeagueData}
                collapsed
              />
              <ReactJson
                name="leagueData"
                src={this.props.leagueData}
                collapsed
              />
            </Paper>
          </Grid>

          {Object.keys(this.props.userData).length !== 0
            ? UserInfoList.map((option, index) => (
                <Grid item xs={12} md={4} lg={4}>
                  <Paper className={fixedHeightPaper}>
                    <UserInfo
                      handleChange={this.props.handleChange}
                      options={option}
                      userData={this.props.userData}
                      getRandomCard={this.getRandomCard}
                      addLeagueBots={this.addLeagueBots}
                    />
                  </Paper>
                </Grid>
              ))
            : ""}
        </Grid>
      </Container>
    );
  }
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(withSnackbar(User));
