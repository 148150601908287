import React from 'react';
import clsx from 'clsx';
import { withStyles, Container, Grid, Paper, Button} from '@material-ui/core';
import { Component } from 'react';
import { PropTypes } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    minHeight: 240,
  },
  paperCol: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  updateButton: {
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
  },
  startPaper: {
    padding: theme.spacing(2),
    display: "flex",
    backgroundColor: "#546bd6",
    justifyContent: "center",
  }, 
  inputBar: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },  
  autocomplete: {
    display: "inline-flex"
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      fontWeight: 800,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);



class UpdateFeature extends Component {

  state = {
    featureNumbers : [],
    featureCohorts :[[]],
    featureCohortInfos :[[]],
    featureNames : [],
    selectedFeature : '',
    selectedCohort : '',
    selectedFeatureIndex : -1,
    selectedCohortIndex : -1,
    gameCode : ''
  };

  componentWillMount(){
    this.updateFeatureLists()
  }

  componentDidUpdate(prevProps){
    if (prevProps.cohortConfig["cohort_config"] != this.props.cohortConfig["cohort_config"]){
      this.updateFeatureLists()
    }
  }
  

  updateFeatureLists = () => {
    var cohortConfigMap = this.props.cohortConfig["cohort_config"]
    var featureNames = []
    var featureNumbers = []
    var featureCohorts = []
    var featureCohortInfos = []
    Object.keys(cohortConfigMap).map(key => {
      var cohortInfo =  cohortConfigMap[key]["cohort_info"]
      if (cohortInfo && cohortInfo["type"] && Object.keys(cohortInfo).length > 2){
        featureNames.push(cohortInfo["type"])
        featureNumbers.push(key)
        var cohorts = []
        var cohortInfos = []
        Object.keys(cohortInfo).map(key => {
          if(key != "type"){
            cohorts.push(key)
            cohortInfos.push(cohortInfo[key]) 
          }
        })
        featureCohorts.push(cohorts)
        featureCohortInfos.push(cohortInfos)
      }
    })
    this.setState({featureNumbers : featureNumbers , featureNames : featureNames, featureCohortInfos : featureCohortInfos , featureCohorts : featureCohorts})
  }

  validateInputs = (gameCode , featureIndex , cohortIndex ) => {
    if(gameCode.length != 8){
      this.props.enqueueSnackbar("Enter a valid GameCode", {
        variant: "error",
      });
      return false
    }
    if(featureIndex === -1){
      this.props.enqueueSnackbar("feature Name Invalid", {
        variant: "error",
      });
      return false
    }
    if(cohortIndex === -1){
      this.props.enqueueSnackbar("Cohort Invalid", {
        variant: "error",
      });
      return false
    }
    return true
  }

  addUserToCohortAndEnableFeature = () => {
    var gameCode = this.state.gameCode
    var featureIndex = this.state.selectedFeatureIndex
    var cohortIndex = this.state.selectedCohortIndex
    if (!this.validateInputs(gameCode,featureIndex,cohortIndex)){
      return
    }
    var featureNumber = this.state.featureNumbers[featureIndex]
    var featureCohortNumber = this.state.featureCohorts[featureIndex][cohortIndex]

    var payload = {"game_code" : gameCode , "feature_number" : featureNumber , "feature_cohort":Number(featureCohortNumber)}
    this.props.putCohortConfig(payload)
  }

  selectFeature = (val) => {
    var currIndex = this.state.featureNames.indexOf(val)
    this.setState({selectedFeature : val , selectedFeatureIndex :currIndex , selectedCohort : ''})
  }

  selectCohort = (val) => {
    var selectedCohortIndex = this.state.featureCohortInfos[this.state.selectedFeatureIndex].indexOf(val)
    this.setState({selectedCohort : val , selectedCohortIndex : selectedCohortIndex})
  }

  getCohortList = () => {
    if (this.state.featureCohortInfos[this.state.selectedFeatureIndex]){
      return this.state.featureCohortInfos[this.state.selectedFeatureIndex]
    }
    return []
  }

  handleGameCodeChange = event => {
    this.setState({gameCode : event.target.value})
  }

  render() {
      const { classes } = this.props;
      const fixedHeightPaper = clsx(classes.paperCol, classes.fixedHeight);
      return (
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}></Grid>
             
          </Grid>
          <Grid container spacing={3} style = {{marginTop : 30}}>
          <Paper className={classes.startPaper}>
                 <Grid justify="space-between" container spacing={3}>
                  <Grid item className={classes.inputBar}>
                    <CssTextField
                      className={classes.margin}
                      label="GameCode"
                      id="user-gameCode"
                      name="user-gameCode"
                      autoComplete="on"
                      onChange={this.handleGameCodeChange}
                      variant="outlined"
                      value={this.state.gameCode}
                    />
                    <Autocomplete
                      className={classes.autocomplete}
                      id="feature-name"
                      onChange={(ev, value) => {this.selectFeature(value)}}
                      value={this.state.selectedFeature}
                      options={this.state.featureNames}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option.id === value.id}
                      style={{ width: 400 , margin : 20}}
                      renderInput={(params) => <CssTextField {...params} label="Feature Name" variant="outlined" />}
                    />
                    <Autocomplete
                      className={classes.autocomplete}
                      id="feature-cohort"
                      onChange={(ev, value) => {this.selectCohort(value)}}
                      value={this.state.selectedCohort}
                      options={this.getCohortList()}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option.id === value.id}
                      style={{ width: 400 , margin : 20}}
                      renderInput={(params) => <CssTextField {...params} label="Select Cohort" variant="outlined" />}
                    />
                    <Button
                      className={classes.updateButton}
                      aria-haspopup="true"
                      aria-controls="lock-menu"
                      variant="contained"
                      onClick={this.addUserToCohortAndEnableFeature}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
          </Grid>
        </Container >
      );
    }
  }
  
  UpdateFeature.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
  export default withStyles(useStyles)(withSnackbar(UpdateFeature));